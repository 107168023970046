import Vue from 'vue'
import VueRouter from 'vue-router'
//解决路由重复的问题
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const Home = () => import('../views/home/Home')
const Category = () => import('../views/category/Category')
const Profile = () => import('../views/profile/Profile')
const Evaluation = () => import('../views/evaluation/evaluation')
const Search = () => import('../views/search/Search')
const HsIndex = () => import('../views/hsIndex/HsIndex')
const Price = () => import('../views/price/Price')

const routes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/category',
    name: 'category',
    component: Category
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },

  {
    path: '/evaluation',
    name: 'evaluation',
    component: Evaluation
  },
  {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path: '/hsIndex',
    name: 'hsIndex',
    component: HsIndex
  },
  {
    path: '/price',
    name: 'price',
    component: Price
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
