import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'

//复制功能插件
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)



//vant组件
import { Button, NoticeBar, Swipe, SwipeItem, Icon, Search, Tab, Tabs, Dialog, Toast, NavBar} from 'vant'
Vue.use(Button).use(NoticeBar).use(Swipe).use(SwipeItem).use(Icon).use(Search).use(Tab).use(Tabs).use(Dialog).use(Toast).use(NavBar)

//配置keepAlive动态缓存
import keepAliveConf from './keepAliveConf.js'
Vue.mixin({
  methods: {
    // 传入需要重置的组件名字
    resetKeepAive(name) {
      const conf = keepAliveConf.value;
      let arr = keepAliveConf.value.split(',');
      if (name && typeof name === 'string') {
        let i = arr.indexOf(name);
        if (i > -1) {
          arr.splice(i, 1);
          keepAliveConf.value = arr.join();
          setTimeout(() => {
            keepAliveConf.value = conf
          }, 500);
        }
      }
    },
  }
})


Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default app;
