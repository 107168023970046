<template>
  <div id="app">
    <keep-alive :include="keepAliveConf.value">
      <router-view class="child-view" :key="$route.fullPath"></router-view>
    </keep-alive>
  </div>
</template>
<script>
  import keepAliveConf from './keepAliveConf'

  export default {
    name: 'app',
    data(){
      return{
        keepAliveConf: {}
      }
    },
    created () {
      this.keepAliveConf = keepAliveConf
    }
  }
</script>
<style lang="less">
  @import "./assets/css/base.css";

  body {
    font-size: 16px;
    min-height: 100vh;
    background-color: #f8f8f8;
    padding-bottom: env(safe-area-inset-bottom);
  }

</style>
